@import 'mixins.scss';

.vb__sidebar {
  width: 280px;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  background: $white;
  transform: translateX(280px);
  @include transition-slow();

  h6 {
    margin-bottom: rem(15);
  }

  :global(.ant-collapse-content-box) {
    padding: 16px 8px !important;
  }

  :global(.ant-collapse-item) {
    &:last-child {
      border-bottom: none;
    }
  }

  &__toggled {
    transform: translateX(0);
    box-shadow: $shadow;
  }

  // mobile toggle button
  &__close {
    float: right;
    position: relative;
    top: 3px;
  }

  &__inner {
    padding: rem(25) rem(20);
  }

  &__fixColorPicker {
    position: relative;
    top: -4px;
  }

  &__fixSwitch {
    position: relative;
    top: -5px;
  }

  &__select {
    &__item {
      margin-left: rem(2);
      display: block;
      width: 24px;
      height: 24px;
      border: 2px solid transparent;
      border-radius: 24px;
      float: left;
      padding: 1px;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 18px;
      }

      &__active {
        border: 2px solid $blue;
      }

      &__white {
        &:after {
          border: 1px solid $gray-2;
          background: $white;
        }
      }

      &__gray {
        &:after {
          background: $gray-1;
        }
      }

      &__blue {
        &:after {
          background: $blue;
        }
      }

      &__black {
        &:after {
          background: $black;
        }
      }

      &__img {
        &:after {
          background: linear-gradient(
            to right,
            rgba(240, 240, 240, 1) 0%,
            rgba(210, 210, 220, 1) 100%
          );
        }
      }
    }
  }
}

// dark theme
[data-vb-theme='dark'] {
  .vb__sidebar {
    background: $dark-gray-6;

    &__select {
      &__item {
        &__black {
          &:after {
            background: $dark-gray-4;
          }
        }
      }
    }
  }
}
